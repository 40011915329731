@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  background: #000;
  height: 100vh;
  font-family: "Montserrat", sans-serif;
}

.price-limited {
  color: white;
}

.lastinneerr {
  width: 180px;
}

.get-amount-input {
  height: 10px;
  color: white;
  margin-top: 10px;
}

.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  // text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}

.grey {
  color: #888 !important;
}

.ptb20 {
  padding: 20px 0;
}

.ptb60 {
  padding: 60px 0;
}

.yelo_iner {
  background: var(--primary-gradient, linear-gradient(90deg, #f9c924 0%, #fff98c 26.52%, #e4af18 73.21%, #ffd440 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 14px;

  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.cursor-pointer {
  cursor: pointer;
}

.purple {
  color: #6c01c3;
}

.yellow {
  color: #ffc13d;
}

.border-img {
  border-radius: 10px;
}

.common {
  color: #eab721;
}

.green {
  color: #6dff39;
}

.red {
  color: #f41e5e;
}

.white {
  color: #fff !important;
}

.blue {
  color: #133572;
}

.navbar-toggle {
  background-color: #333;

  .icon-bar {
    background-color: #eee;
  }
}

.fade.profilemodal.modal.show {
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
}

.login1 {
  border-radius: 5px;
  background: #020202;
  // box-shadow: 0px 0px 14px 0px rgba(255, 212, 64, 0.20) inset;
  padding: 15px 38px;
  border: none;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  border: 1px solid  #E1A800;
  &:hover{
      background:  var(--primary-gradient,
      linear-gradient(90deg, #f9c924 0%, #fff98c 26.52%, #e4af18 73.21%, #ffd440 100%));
      color: #000;
  }
}

@media only screen and (max-width: 600px) {
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    outline: none;

    &:focus {
      outline: none;
    }
  }

  .main-footer {
    text-align: center;

    .text-right {
      text-align: center !important;
    }
  }

  h1 {
    font-size: 2.5rem !important;
  }

  .ptb {
    padding: 30px 0;
  }

  .xs-0 {
    padding: 0px;
  }

  .main-mint,
  .main-banner {
    .inner-content {
      .modal-content {
        .for-padding {
          padding: 0 0px !important;
        }
      }
    }
  }

  .main-give {
    .right-side {
      margin-top: 30px !important;

      h5 {
        font-weight: 500;
      }

      li {
        margin-right: 2px !important;
      }
    }
  }
}

// 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  h1 {
    font-size: 3rem !important;
  }

  .ptb {
    padding: 20px 0;
  }
}

a {
  text-decoration: none;
  color: #fff;
}

iframe {
  position: unset !important;
  top: 0px !important;
  left: 0px !important;
  width: 0px !important;
  height: 0px !important;
}

button {
  cursor: pointer;
}

@media (max-width: 600px) {
  .col-md-6 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-8 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-4 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

button:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

.form-control:focus {
  box-shadow: unset;
}

@media (max-width: 600px) {
  .modal.show .modal-dialog {
    max-width: 100% !important;
  }
}

.solld_oud {
  padding: 15px;
  color: var(--primary-color, #eab721);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea:focus-visible {
  outline: none !important;
}

button,
input,
optgroup,
select,
textarea:focus {
  outline: none !important;
}

button.close {
  opacity: 1;
}

.endbtn {
  button {
    border-radius: 5px;
    background: var(--primary-gradient,
        linear-gradient(90deg, #f9c924 0%, #fff98c 26.52%, #e4af18 73.21%, #ffd440 100%));
    padding: 18px 45px;
    width: 100%;
    font-size: 16px;
    color: #000;

    font-weight: 600;
    line-height: 100%;
    /* 16px */

    border: none;
    margin-top: 40px;
  }
}

.back-btn {
  border-radius: 130px;
  border: 1px solid rgba(234, 183, 33, 0.1);
  background: rgba(234, 183, 33, 0.05);
  display: inline-flex;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: #eab721;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 10px;
}

.custom-container {
  max-width: 1370px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.global-img {
  border-radius: 10px;
  background: #3bdaea;
  box-shadow: 0px 0px 40px 0px rgba(59, 218, 234, 0.5);
  padding: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}