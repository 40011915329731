.golden {
    background: url(../../../src/Assests/bannerimg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 238px;

    .mainheadss {
        padding-bottom: 145px;

        h4 {
            color: var(--White, #FFF);
            text-align: center;
            font-size: 23px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%;
            text-transform: capitalize;
        }

    }

    .inner-mainhead {
        h2 {
            color: var(--Primary-Color, #EAB721);
            font-size: 35px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            margin-bottom: 30px;
        }
    }

    .parenthead {
        h3 {
            color: #FFF;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            margin-bottom: 20px;
        }

        p {
            color: #FFF;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            margin-bottom: 30px;
        }
    }

}

@media(max-width:600px){
    .golden {
        padding-top: 161px;
    }
    .golden .mainheadss h4{
        font-size: 16px;
    }
    .golden .mainheadss{
        padding-bottom: 65px;
    }
    .golden .inner-mainhead h2{
        font-size: 16px;
        margin-bottom: 18px;
    }
    .golden .parenthead h3{
        font-size: 15px;
        margin-bottom: 15px;
    }
    .golden .parenthead p{
        font-size: 12px;
        margin-bottom: 20px;
    }
}