.main-currentproperties {
    margin-top: 145px;
    // margin-bottom: 110px;
    .main-heading-div {
        display: flex;
        justify-content: space-between;
        align-items: self-start;
        margin-bottom: 60px;
        .main-deading {
            margin: 0px auto;
            text-align: center;
            color: #fff;

            font-size: 45px;

            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            padding-top: 10px;
        }
        .back-top-btn {
            border-radius: 130px;
            border: 1px solid rgba(234, 183, 33, 0.1);
            background: rgba(234, 183, 33, 0.05);
            display: inline-flex;
            padding: 10px 15px;
            justify-content: center;
            align-items: center;
            grid-gap: 6px;
            gap: 6px;
            color: #eab721;

            font-size: 14px;

            font-weight: 400;
            line-height: normal;
            grid-gap: 10px;
            gap: 10px;
        }
    }
    .main-card {
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.04);
        background: #020202;

        .top_card_img {
            img.img-fluid.card_img {
                min-width: 100%;
                // min-height: 282px;
                // max-width: 434px;
                // max-height: 282px;
                border-radius: 10px;
            }
        }
        .card_inner_text {
            // padding: 15px 15px 22px 15px;
            .card-inner-hedng {
                color: #fff;

                font-size: 18px;

                font-weight: 600;
                line-height: normal;
                text-transform: capitalize;
                padding: 15px;
            }
            .card_inner_text_text {
                border: 1px solid #0f0f0f;
                padding: 15px;

                .card_inner_flex {
                    /* padding: 15px; */
                    display: flex;
                    justify-content: space-between;
                    text-align: center;
                    h6.offertyp {
                        color: #8d8d8c;

                        font-size: 14px;

                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                    }
                    h6.yellow_inerbtn {
                        border-radius: 70px;
                        background: #eab72130;

                        padding: 4px 10px;
                        color: var(--primary-color, #eab721);

                        font-size: 12px;

                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                    }
                    h6.yellow_inerdig {
                        color: #8d8d8c;

                        font-size: 14px;

                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                        span.yelo_iner {
                            background: var(
                                --primary-gradient,
                                linear-gradient(90deg, #f9c924 0%, #fff98c 26.52%, #e4af18 73.21%, #ffd440 100%)
                            );
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;

                            font-size: 14px;

                            font-weight: 500;
                            line-height: normal;
                            text-transform: capitalize;
                        }
                    }
                }
                h3.solld_oud {
                    padding: 15px;
                    color: var(--primary-color, #EAB721);
                    font-family: Montserrat;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-transform: capitalize;
                }
            }
        }
    }
}

@media(max-width:800px) {

.main-currentproperties .main-heading-div {
    display: flex;

    margin-bottom: 24px;
    flex-direction: column;
    padding: 20px;
}
}
@media(max-width:600px) {
.main-currentproperties .main-heading-div .main-deading {
    margin: 0px auto;
    text-align: center;
    color: #fff;
    font-size: 25px !important;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    padding-top: 35px;
}
.main-currentproperties .main-heading-div{
    padding: 20px 0;
}
}

