.entercode {
    padding-top: 100px;

    .backbutton {
        a {
            border-radius: 130px;
            border: 1px solid rgba(234, 183, 33, 0.10);
            background: rgba(234, 183, 33, 0.05);
            padding: 10px 15px;
            width: 100%;
            color: #EAB721;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            align-items: center;
            gap: 10px;
            max-width: 89px;
        }
    }

    .enterode {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .maincard {
            border-radius: 10px;
            background: #020202;
            box-shadow: 0px 3px 50px 0px rgba(0, 0, 0, 0.04);
            padding: 50px;
            width: 100%;

            .resethead {
                h2 {
                    color: var(--white, #FFF);
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 110%;
                    text-transform: uppercase;
                    margin-bottom: 15px;
                    text-align: center;
                }

                p {
                    color: var(--light-text, #3E3E3E);
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 130%;
                    // width: 405px;
                    // margin: 0 auto;
                    margin-bottom: 40px;
                }
            }

            .parentinputs {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 19px;
                margin-top: 10px;

                .code {
                    border-radius: 8px;

                    border-radius: 8px;
                    border: 1px solid var(--primary-color, #EAB721);
                    background: #060606;
                    padding: 20px 17.833px 19px 18px;
                    // width: 57.83px;
                    // height: 76px;
                    width: 100%;
                    color: var(--primary-color, #EAB721);
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: var(--primary-color, #EAB721);
                    font-size: 34px;
                    font-weight: 700;
                    line-height: 110%;
                    text-transform: uppercase;
                    text-align: center;

                    &:focus {
                        border: 1px solid (--primary-color, #EAB721);
                    }

                    &::placeholder {
                        color: var(--primary-color, #EAB721);
                        text-align: center;
                        font-size: 34px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 110%;
                        text-transform: uppercase;
                    }
                }



            }

            .leftbtn {
                button {
                    border-radius: 5px;
                    background: rgba(234, 235, 240, 0.04);
                    padding: 18px 45px;
                    width: 100%;
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 100%;
                    border: none;
                    margin: 40px 0px;
                }

                p {
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: normal;
                    text-align: center;

                    span {
                        color: var(--primary-color, #EAB721);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 900;
                        line-height: normal;
                    }
                }
            }

        }
    }
}

.googlemodal {
    .modal-dialog {
        max-width: 524px;

        .modal-content {
            border-radius: 5px;
            border: 1px solid var(--stroke, #0C0C0C);
            background: #060606;
            box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.05);
            padding: 30px 30px 40px 30px;

            .modal-header {
                border-bottom: none;
                border-radius: 8px;
                border: 1px solid var(--stroke, #0C0C0C);
                padding: 12px 10px;
                margin-bottom: 50px;

                .modal-title {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 100%;
                    text-transform: uppercase;
                    color: #FFFFFF;
                }

                .btn-close {
                    background: url(../../../src/Assests/close-circlemodal.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: contain;
                    width: 22px;
                    height: 22px;
                    box-shadow: none !important;
                    opacity: unset;
                    position: absolute;
                    padding: 0px;
                    margin: 0px;
                    top: 40px;
                    right: 45px;
                }
            }

            .modal-body {
                padding: 0px;

                .mainheading {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    h2 {
                        color: var(--white, #FFF);
                        text-align: center;
                        font-family: Montserrat;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 110%;
                        margin-bottom: 50px;
                        width: 305px;
                    }
                }

                .qrimg {
                    width: 192px;
                    height: 192px;
                    object-fit: cover;
                    object-position: center;
                    margin: 0 auto;

                    .qrcode {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                .file {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    object-position: center;
                    margin: 0 auto;
                    padding-bottom: 24px;

                    .fileinner {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                .innerpara {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    p {
                        color: var(--light-text, #3E3E3E);
                        text-align: center;

                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 110%;
                        margin: 17px 0px;
                    }

                    h6 {
                        color: var(--primary-color, #EAB721);
                        text-align: center;
                        margin-bottom: 15px;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 110%;
                    }

                    .grey {
                        color: var(--light-text, #3E3E3E);
                        text-align: center;
                        margin-bottom: 50px;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 110%;
                        width: 348px;
                    }

                }

                .next {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 20px;

                    button {
                        border-radius: 5px;
                        background: var(--primary-gradient, linear-gradient(90deg, #F9C924 0%, #FFF98C 26.52%, #E4AF18 73.21%, #FFD440 100%));
                        padding: 14px 81px;
                        width: 100%;
                        color: #000;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        border: none;
                    }

                    .backbtn {
                        padding: 14px 81px;
                        color: #FFF;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                        width: 100%;
                        border: 1px solid rgba(255, 212, 64, 0.20) !important;
                        position: relative;
                        border: 1px solid transparent;
                        background: #000;
                        background-clip: padding-box;
                        border-radius: 5px;
                    }


                }

                .googleinput {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border-radius: 5px;
                    border: 1px solid var(--stroke, #0C0C0C);
                    background: #060606;
                    padding: 18px 15px;
                    margin-bottom: 50PX;

                    h6 {
                        color: var(--white, #FFF);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        /* 14px */
                        letter-spacing: 0.1px;
                        padding-right: 10px;
                        border-right: 1px solid #A9A8A8;
                    }

                    input {
                        background-color: transparent;
                        border: none;
                        padding: 0;
                        padding-left: 10px;
                        color: var(--field-text, #FFF);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        /* 14px */
                        letter-spacing: 0.1px;

                        &::placeholder {
                            color: var(--field-text, #1F1F1E);
                        }
                    }
                }

                .successfully {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    .mainimagesss {
                        width: 206px;
                        height: 206px;
                        object-fit: cover;
                        object-position: center;

                        .done {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }

                    .innertextsss {
                        h6 {
                            color: var(--white, #FFF);
                            text-align: center;
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 110%;
                        }

                        p {
                            color: var(--light-text, #3E3E3E);
                            text-align: center;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 100%;
                            /* 12px */
                            letter-spacing: 0.1px;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width:1440px) {
 

    .entercode .enterode .maincard .resethead h2 {
        font-size: 18px;
        white-space: nowrap;
        padding-top: 80px;
    }

    .entercode .enterode .maincard .resethead p {
        font-size: 12px;
    }

    .entercode .enterode .maincard {
        padding: 0px;
    }

    .entercode .enterode .maincard .parentinputs .code {
        padding: 17px 7px;
    }
}
@media(max-width:600px){
    .googlemodal .modal-dialog .modal-content{
        padding: 0px;
    }
    .googlemodal .modal-dialog .modal-content .modal-header .modal-title{
        font-size: 14px;
    }
    .googlemodal .modal-dialog .modal-content .modal-header .btn-close{
        top: 10px;
        right: 20px;
    }
    .googlemodal .modal-dialog .modal-content .modal-body .mainheading h2{
        font-size: 18px;
    }
    .googlemodal .modal-dialog .modal-content .modal-body .next .backbtn{
        padding: 18px 38px;
    }
    .googlemodal .modal-dialog .modal-content .modal-body .next button{
        padding: 18px 38px;
    }
    .googlemodal .modal-dialog .modal-content .modal-body .googleinput h6{
        white-space: nowrap;
    }
}