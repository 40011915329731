.setting {
    padding-top: 110px;

    .backbutton {
        button {
            border-radius: 130px;
            border: 1px solid rgba(234, 183, 33, 0.10);
            background: rgba(234, 183, 33, 0.05);
            padding: 10px 15px;
            width: 100%;
            color: #EAB721;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            align-items: center;
            gap: 10px;
            max-width: 89px;
        }
    }

    .mainhead {
        h2 {
            color: #FFF;
            font-size: 45px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            text-align: center;
            margin-bottom: 60px;
        }
    }

    .settingcard {
        border-radius: 10px;
        border: 4px solid var(--stroke, #0C0C0C) !important;
        background: rgba(255, 255, 255, 0.01);
        padding: 35px 25px;
        width: 100%;
        margin-top: 20px;

        .settingparent {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                .google {
                    display: flex;
                    align-items: baseline;
                    justify-content: flex-start;
                    gap: 17px;
                }

                h4 {
                    color: var(--white, #FFF);
                    margin-bottom: 16px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 21px;
                    /* 131.25% */
                    letter-spacing: 0.32px;
                }

                p {
                    color: var(--light-text, #FFF);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                    /* 19.6px */
                    letter-spacing: 0.28px;
                }

                .inner {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 10px;

                    .settingimage {
                        width: 70px;
                        height: 70px;
                        object-fit: cover;
                        object-position: center;

                        .innerimages {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 100%;
                            object-position: center;
                        }
                    }

                    .textimage {
                        p {
                            color: var(--white, #FFF);
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 21px;
                            /* 131.25% */
                            letter-spacing: 0.32px;
                        }
                    }
                }
            }

            .right {
                .login {
                    padding: 15px 38px;
                    color: #FFF;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;
                    position: relative;
                    border: 1px solid transparent;
                    background: #000;
                    background-clip: padding-box;
                    border-radius: 5px;
                    width: 230px;

                    &::after {
                        content: '';
                        position: absolute;
                        top: -1px;
                        bottom: -1px;
                        left: -1px;
                        right: -1px;
                        background: linear-gradient(90deg, #F9C924 0%, #FFF98C 26.52%, #E4AF18 73.21%, #FFD440 100%);
                        z-index: -1;
                        border-radius: 5px;
                    }

                }

                .toggle-switch {
                    position: relative;
                    display: inline-block;
                    width: 40px;
                    height: 24px;
                    margin: 10px;
                }

                /* Giriş stil */
                .toggle-switch .toggle-input {
                    display: none;
                }

                /* Anahtarın stilinin etrafındaki etiketin stil */
                .toggle-switch .toggle-label {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 44px;
                    height: 24px;
                    border-radius: 16px;
                    background: rgba(178, 190, 195, 0.08);


                    cursor: pointer;
                    transition: background-color 0.3s;
                }

                /* Anahtarın yuvarlak kısmının stil */
                .toggle-switch .toggle-label::before {
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    top: 2px;
                    left: 2px;
                    background: #FFFFFF;
                    transition: transform 0.3s;
                }

                /* Anahtarın etkin hale gelmesindeki stil değişiklikleri */
                .toggle-switch .toggle-input:checked+.toggle-label {
                    background: rgba(178, 190, 195, 0.08);

                }

                .toggle-switch .toggle-input:checked+.toggle-label::before {
                    transform: translateX(20px);
                }

                /* Light tema */
                .toggle-switch.light .toggle-label {
                    background-color: #BEBEBE;
                }

                .toggle-switch.light .toggle-input:checked+.toggle-label {
                    background-color: #9B9B9B;
                }

                .toggle-switch.light .toggle-input:checked+.toggle-label::before {
                    transform: translateX(6px);
                }

                /* Dark tema */
                .toggle-switch.dark .toggle-label {
                    background-color: #4B4B4B;
                }

                .toggle-switch.dark .toggle-input:checked+.toggle-label {
                    background-color: #717171;
                }

                .toggle-switch.dark .toggle-input:checked+.toggle-label::before {
                    transform: translateX(16px);
                }
            }
        }
    }
}

.profilemodal {
    .modal-dialog {
        max-width: 524px;

        .modal-content {
            border-radius: 5px;
            border: 4px solid var(--stroke, #0C0C0C) !important;
            background: #060606;
            box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.05);
            padding: 30px;

            .modal-header {
                border-bottom: none;
                border-radius: 8px;
                border: 1px solid var(--stroke, #0C0C0C);
                padding: 12px 10px;
                margin-bottom: 20px;

                .modal-title {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 100%;
                    text-transform: uppercase;
                    color: #FFFFFF;
                }

                .btn-close {
                    background: url(../../../src/Assests/close-circlemodal.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: contain;
                    width: 22px;
                    height: 22px;
                    box-shadow: none !important;
                    opacity: unset;
                    position: absolute;
                    padding: 0px;
                    margin: 0px;
                    top: 40px;
                    right: 45px;
                }
            }

            .modal-body {
                .parenttick {
                    .text {
                        margin-top: 20px;
                        margin-bottom: 50px;

                        p {
                            color: var(--secondary-white, #FFF);
                            text-align: center;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 140%;

                            span {
                                color: var(--Primary-Color, #EAB721);

                                font-size: 18px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 140%;
                            }
                        }
                    }

                    .okaybtn {
                        button{
                            border-radius: 5px;
                            background: var(--Primary-Gradient, linear-gradient(90deg, #F9C924 0%, #FFF98C 26.52%, #E4AF18 73.21%, #FFD440 100%));
                            padding: 14px 81px;
                            width: 100%;
                            color: #000;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            border: none;
                        }
                      
                    }
                }

                .cardprogress {
                    display: flex;
                    gap: 8px;
                    flex-direction: column;
                    width: 100%;
                    margin-top: 40px;
                    margin-bottom: 15px;

                    .progress {
                        border-radius: 50px;
                        border: 1px solid #171717;
                        background: #0B0B0B;
                        height: 15px;

                        .progress-bar {

                            border-radius: 50px;
                            background: var(--Primary-Color, #EAB721);
                        }
                    }

                    .cardprogressheadings {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 17px;

                        .cardprogresshead {
                            color: #FFF;

                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                        }

                        .cardprogresspara {
                            color: #717171;

                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 100%;
                        }
                    }
                }

                .para {
                    color: #FFF;
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    letter-spacing: 0.1px;
                    margin-bottom: 15px;
                }

                .wrapper {
                    border-radius: 8px;
                    border: 3px dashed var(--stroke, #0C0C0C);
                    background: #060606;
                    padding: 78px 15px;
                    width: 100%;
                    text-align: center;
                }

                .endbtn {
                    button {
                        border-radius: 5px;
                        background: var(--primary-gradient, linear-gradient(90deg, #F9C924 0%, #FFF98C 26.52%, #E4AF18 73.21%, #FFD440 100%));
                        padding: 14px 81px;
                        width: 100%;
                        border: none;
                        margin-top: 50px;
                        color: #000;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }

                .parentinputs {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 19px;
                    margin-top: 10px;

                    .code {
                        border-radius: 8px;

                        border-radius: 8px;
                        border: 1px solid var(--primary-color, #EAB721);
                        background: #060606;
                        padding: 20px 17.833px 19px 18px;
                        // width: 57.83px;
                        // height: 76px;
                        width: 100%;
                        color: var(--primary-color, #EAB721);
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        color: var(--primary-color, #EAB721);
                        font-size: 34px;
                        font-weight: 700;
                        line-height: 110%;
                        text-transform: uppercase;
                        text-align: center;

                        &:focus {
                            border: 1px solid (--primary-color, #EAB721);
                        }

                        &::placeholder {
                            color: var(--primary-color, #EAB721);
                            text-align: center;
                            font-size: 34px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 110%;
                            text-transform: uppercase;
                        }
                    }



                }

                .digitbtn {
                    button {
                        border-radius: 5px;
                        background: rgba(234, 235, 240, 0.04);
                        padding: 18px 45px;
                        width: 100%;
                        color: #FFF;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 100%;
                        border: none;
                        margin-top: 50px;
                    }
                }

                .mainimage {
                    width: 206px;
                    height: 206px;
                    object-fit: cover;
                    object-position: center;
                    margin: 0 auto;

                    .success {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                .disabletext {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    a {
                        color: #FFF;
                        text-align: center;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 110%;
                    }
                }

                input {
                    border-radius: 5px;
                    border: 1px solid var(--stroke, #0C0C0C);
                    background: #060606;
                    padding: 18px 15px;
                    width: 100%;
                    color: #FFF;
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    /* 14px */
                    letter-spacing: 0.1px;

                    &::placeholder {
                        color: var(--field-text, #3E3E3E);
                        font-feature-settings: 'clig' off, 'liga' off;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        /* 14px */
                        letter-spacing: 0.1px;
                    }
                }

                .blance-image {
                    width: 184px;
                    height: 184px;
                    object-fit: cover;
                    object-position: center;
                    margin: 0 auto;

                    .innerblnc {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                .paraemail {
                    p {
                        color: var(--white, #FFF);
                        text-align: center;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 110%;
                        margin-top: 10px;
                        margin-bottom: 50px;
                    }
                }

                .next {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 20px;
                    margin-top: 25px;

                    button {
                        border-radius: 5px;
                        background: var(--primary-gradient, linear-gradient(90deg, #F9C924 0%, #FFF98C 26.52%, #E4AF18 73.21%, #FFD440 100%));
                        padding: 14px 38px;
                        width: 100%;
                        color: #000;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        border: none;
                    }

                    .backbtn {
                        padding: 14px 38px;
                        color: #FFF;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                        width: 100%;
                        border: 1px solid rgba(255, 212, 64, 0.20) !important;
                        position: relative;
                        border: 1px solid transparent;
                        background: #000;
                        background-clip: padding-box;
                        border-radius: 5px;
                    }


                }

                .firstinput {
                    margin-bottom: 24px;
                }

                .emailinput {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border-radius: 5px;
                    border: 1px solid var(--stroke, #0C0C0C);
                    background: #060606;
                    padding: 18px 15px;

                    h6 {
                        color: var(--white, #FFF);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        /* 14px */
                        letter-spacing: 0.1px;
                        padding-right: 10px;
                        border-right: 1px solid #A9A8A8;
                        white-space: nowrap;
                    }

                    input {
                        background-color: transparent;
                        border: none;
                        padding: 0;
                        padding-left: 10px;
                        color: var(--field-text, #FFF);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        /* 14px */
                        letter-spacing: 0.1px;
                        width: 100%;

                        &::placeholder {
                            color: #3E3E3E;
                        }
                    }
                }

                .parentinputmodal {
                    position: relative;

                    .emailinput {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        border-radius: 5px;
                        border: 1px solid var(--stroke, #0C0C0C);
                        background: #060606;
                        padding: 18px 15px;

                        h6 {
                            color: var(--white, #FFF);
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 100%;
                            /* 14px */
                            letter-spacing: 0.1px;
                            padding-right: 10px;
                            border-right: 1px solid #A9A8A8;
                            white-space: nowrap;
                        }

                        input {
                            background-color: transparent;
                            border: none;
                            padding: 0;
                            padding-left: 10px;
                            color: #3E3E3E;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 100%;
                            /* 14px */
                            letter-spacing: 0.1px;
                            width: 100%;

                            &::placeholder {
                                color: #3E3E3E;
                            }
                        }
                    }

                    .gold {
                        position: absolute;
                        top: 20px;
                        right: 20px;
                        color: var(--primary-color, #EAB721);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        /* 14px */
                        letter-spacing: 0.1px;
                    }

                    .paratext {
                        color: var(--light-text, #3E3E3E);
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        /* 12px */
                        letter-spacing: 0.1px;
                        margin-top: 12px;
                        margin-bottom: 25px;
                    }


                }

                .security {
                    color: var(--primary-color, #EAB721);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    /* 14px */
                    letter-spacing: 0.1px;
                    margin-bottom: 25px;
                }

                .successfully {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    .mainimagesss {
                        width: 206px;
                        height: 206px;
                        object-fit: cover;
                        object-position: center;

                        .done {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }

                    .innertextsss {
                        h6 {
                            color: var(--white, #FFF);
                            text-align: center;
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 110%;
                        }

                        p {
                            color: var(--light-text, #3E3E3E);
                            text-align: center;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 100%;
                            /* 12px */
                            letter-spacing: 0.1px;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width:600px) {
    .setting .mainhead h2 {
        font-size: 25px;
        margin-bottom: 20px;
    }

    .setting .settingcard {
        padding: 20px;
    }

    .setting .settingcard .settingparent {
        flex-direction: column;
        align-items: flex-start;
    }

    .setting .settingcard .settingparent .right .login {
        margin-top: 35px;
    }

    .setting .settingcard .settingparent .left p {
        font-size: 12px;
    }

    .profilemodal .modal-dialog .modal-content .modal-body .endbtn button {
        font-size: 14px;
        white-space: nowrap;
    }

    .profilemodal .modal-dialog .modal-content .modal-body .next button {
        padding: 18px 38px;
    }

    .profilemodal .modal-dialog .modal-content .modal-body .next .backbtn {
        padding: 18px 38px;
        font-size: 16px;
    }

    .profilemodal .modal-dialog .modal-content {
        padding: 0px;
    }

    .profilemodal .modal-dialog .modal-content .modal-header .modal-title {
        font-size: 13px;
    }

    .profilemodal .modal-dialog .modal-content .modal-header .btn-close {
        top: 8px;
        right: 15px;
    }
    .dvydccvdvcdvcd{
        width: 100%;
    }
    .hscvvscyvscyvc{
        width: 100% !important;
        margin: 0 auto;
    }
    .setting .settingcard .settingparent .right{
        width: 100%;
    }
    .setting .settingcard .settingparent .right .login{
        width: 100%;
    }
}