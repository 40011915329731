.faqs {
    padding-bottom: 70px;

    .faqsheads {
        h2 {
            color: #FFF;
            text-align: center;
            font-size: 45px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            margin-bottom: 60px;
        }


    }

    .faqsacc {
        .accordion {
            border: 1px solid #0C0C0C;
            background: rgba(255, 255, 255, 0.00);
            backdrop-filter: blur(2px);
            padding: 30px 38px;
            border-radius: 6px;

            .accordion-button::after {
                background: url(../../Assests/arrowdown.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                width: 12px;
                height: 12px;
                padding: 0px;
                transform: rotate(180deg);
                margin: 0px;
            }

            .accordion-button:not(.collapsed)::after{
                transform: rotate(0deg);
            }

            .accordion-item {
                background: rgba(255, 255, 255, 0.00) !important;
                border: none !important;

                .accordion-header {
                    border-bottom: none;

                    .accordion-button {
                        border-radius: 6px;
                        background: transparent;
                        box-shadow: none !important;
                        outline: none !important;
                        padding: 0px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        h2 {
                            color: #FFF;
                            text-align: center;

                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }

                }

                .accordion-body {
                    p {
                        color: #8D8D8C;

                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%
                    }
                }
            }
        }
    }
}
@media(max-width:600px){
    .faqs .faqsheads h2{
        font-size: 22px;
    }
    .faqs .faqsacc .accordion .accordion-item .accordion-header .accordion-button h2{
        font-size: 14px;
    }
    .faqs .faqsacc .accordion .accordion-item .accordion-body p{
        font-size: 14px;
    }
    .faqs .faqsacc .accordion .accordion-item .accordion-header .accordion-button h2{
        text-align: start;
    }
}