.success {
    padding-top: 70px;

    .backbutton {
        a {
            border-radius: 130px;
            border: 1px solid rgba(234, 183, 33, 0.10);
            background: rgba(234, 183, 33, 0.05);
            padding: 10px 15px;
            width: 100%;
            color: #EAB721;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            align-items: center;
            gap: 10px;
            max-width: 89px;
        }
    }

    .parentpic {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .maincard {
            border-radius: 10px;
            background: #020202;
            box-shadow: 0px 3px 50px 0px rgba(0, 0, 0, 0.04);
            padding: 50px;
            width: 100%;

            .passwordimage {
                width: 250px;
                height: 250px;
                object-fit: cover;
                object-position: center;
                margin: 0 auto;

                .innerpassword {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            .heading {
                h2 {
                    color: var(--white, #FFF);
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 110%;
                    /* 24.2px */
                    text-transform: uppercase;
                    margin-top: 50px;
                    margin-bottom: 15px;
                    text-align: center;
                }

                p {
                    color: var(--light-text, #3E3E3E);
                    font-family: Montserrat;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 130%;
                    margin-bottom: 40px;
                    text-align: center;
                }

                button {
                    border-radius: 5px;
                    background: var(--primary-gradient, linear-gradient(90deg, #F9C924 0%, #FFF98C 26.52%, #E4AF18 73.21%, #FFD440 100%));
                    padding: 18px 45px;
                    width: 100%;
                }
            }
        }
    }
}

@media(max-width:600px){
    .success .parentpic .maincard{
        padding: 0px;
    }
    .success .parentpic .maincard .passwordimage .innerpassword{
        padding-top: 100px;
    }
}