.main-property{
    padding: 0px;
    .investmentcard {
        border-radius: 10px;
        background: #0A0A0A;
        padding: 20px 25px;
        width: 100%;

        .innerinvestcard {
            border-radius: 8.197px;
            border: 0.82px solid rgba(255, 255, 255, 0.04);
            background: #0A0A0A;
            width: 100%;

            .homeimage {
               max-width: 352px;
               width: 100%;
                height: 237px;
                object-fit: cover;
                object-position: center;

                .innerhome {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 8.197px 8.197px 0px 0px;
                }
            }

            .lowertext {
                padding: 15px 15px 7px 15px;

                .texthead {
                    h2 {
                        color: #FFF;
                        font-size: 14.754px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        text-transform: capitalize;
                    }

                }

                .brdr {
                    border: 1px solid #0F0F0F;
                    margin-top: 15px;
                    margin-bottom: 17px;
                    width: 100%;
                }

                .propertytype {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 10px;

                    .left {
                        h6 {
                            color: #8D8D8C;
                            font-size: 11.475px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: capitalize;
                        }
                    }

                    .right {
                        p {
                            color: #8D8D8C;
                            font-size: 11.475px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: capitalize;

                            span {
                                background: var(--primary-gradient, linear-gradient(90deg, #F9C924 0%, #FFF98C 26.52%, #E4AF18 73.21%, #FFD440 100%));
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                font-size: 11.475px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                text-transform: capitalize;
                            }
                        }

                        .rental {
                            button {
                                border-radius: 57.377px;
                                background: rgba(234, 183, 33, 0.19);
                                padding: 3.279px 8.197px;
                                width: 100%;
                                border: none;
                                color: var(--primary-color, #EAB721);
                                font-size: 9.836px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media(max-width:1199px){
    .main-property .investmentcard .innerinvestcard .homeimage{
        max-width: unset;
    }
    .main-property .investmentcard{
        padding: 0px;
    }
    .main-property .investmentcard .innerinvestcard{
        margin-bottom: 15px;
    }
}