.main-dashbord {
    // padding: 0px 15px;

    .mainheading {
        h2 {
            color: var(--white, #FFF);
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.56px;
            margin-bottom: 27px;
        }
    }

    .dashbordcard {
        display: flex;
        justify-content: center;
        justify-content: flex-start;
        gap: 40px;

        .innercard {
            border-radius: 10px;
            background: #0A0A0A;
            padding: 25px 20px;
            width: 100%;
            border: 4px solid var(--stroke, #0C0C0C) !important;
            // max-width: 548px;

            .maincardparent {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .right {
                    h6 {
                        color: var(--white, #FFF);
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        /* 18px */
                        text-transform: capitalize;
                    }
                }

                .left {
                    button {
                        border-radius: 5px;
                        background: #0A0A0A;
                        box-shadow: 0px 0px 14px 0px rgba(255, 212, 64, 0.20) inset;
                        padding: 15px 38px;
                        border: 1px solid #EAB721;
                        color: var(--primary-color, #EAB721);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                    }

                    h4 {
                        color: var(--primary-color, #EAB721);
                        font-size: 26px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 100%;
                        /* 26px */
                        text-transform: capitalize;
                    }

                    p {
                        color: var(--light-text, #3E3E3E);
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        /* 18px */
                        margin-top: 10px;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }

    .investment {
        h2 {
            color: var(--white, #FFF);
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 20px */
            text-transform: capitalize;
            margin-top: 40px;
            margin-bottom: 25px;
        }

        .my-table {

            th {
                background-color: transparent;
                color: white;
            }

            td {
                background-color: transparent;
                color: white;
            }
        }
    }

    .investmentcard {
        border-radius: 10px;
        background: #0A0A0A;
        padding: 20px 25px;
        width: 100%;

        .innerinvestcard {
            border-radius: 8.197px;
            border: 0.82px solid rgba(255, 255, 255, 0.04);
            background: #0A0A0A;
            width: 100%;

            .homeimage {
                max-width: 352px;
                width: 100%;
                height: 237px;
                object-fit: cover;
                object-position: center;

                .innerhome {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 8.197px 8.197px 0px 0px;
                }
            }

            .lowertext {
                padding: 15px 15px 7px 15px;

                .texthead {
                    h2 {
                        color: #FFF;
                        font-size: 14.754px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        text-transform: capitalize;
                    }

                }

                .brdr {
                    border: 1px solid #0F0F0F;
                    margin-top: 15px;
                    margin-bottom: 17px;
                    width: 100%;
                }

                .propertytype {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 10px;

                    .left {
                        h6 {
                            color: #8D8D8C;
                            font-size: 11.475px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: capitalize;
                        }
                    }

                    .right {
                        p {
                            color: #8D8D8C;
                            font-size: 11.475px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: capitalize;

                            span {
                                background: var(--primary-gradient, linear-gradient(90deg, #F9C924 0%, #FFF98C 26.52%, #E4AF18 73.21%, #FFD440 100%));
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                font-size: 11.475px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                text-transform: capitalize;
                            }
                        }

                        .rental {
                            button {
                                border-radius: 57.377px;
                                background: rgba(234, 183, 33, 0.19);
                                padding: 3.279px 8.197px;
                                width: 100%;
                                border: none;
                                color: var(--primary-color, #EAB721);
                                font-size: 9.836px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }
        }
    }

    .headings {
        h2 {
            color: var(--white, #FFF);
            // font-family: Mada;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            text-transform: capitalize;
            margin-top: 40px;
            margin-bottom: 25px;
        }
    }

    .table-responsive {
        th {
            color:#FFF !important;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.1px;
            padding: 30px;
            vertical-align: middle;
            border: none;
            white-space: nowrap;
            border-bottom: 1px solid #181818;
            background: #0A0A0A;
        }

        td {
            white-space: nowrap;
            padding: 30px;
            vertical-align: middle;
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            // border-top: 1px solid rgba(3, 2, 41, 0.05);
            border-bottom: 1px solid #181818;
            background: #0A0A0A;
        }

    }
}

@media(max-width:1199px) {
    .main-dashbord .investmentcard .innerinvestcard .homeimage {
        max-width: unset;
    }

    .main-dashbord .investmentcard {
        padding: 0px;
    }

    .main-dashbord .investmentcard .innerinvestcard {
        margin-bottom: 15px;
    }
}

@media(max-width:600px) {
    .main-dashbord .dashbordcard {
        flex-direction: column;
    }

    .main-dashbord .investmentcard {
        padding: 0px;
    }

    .main-dashbord .mainheading h2 {
        font-size: 25px;
        margin-bottom: 40px;
    }

    .main-dashbord .dashbordcard .innercard .maincardparent .left h4 {
        font-size: 22px;
    }

    .main-dashbord .dashbordcard .innercard .maincardparent .left p {
        font-size: 16px;
    }
}