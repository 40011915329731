.buy_sell {
    padding: 100px 0px;
    position: relative;
    overflow: hidden;
    height: 100vh;
    margin-bottom: -206px;
    width: 100%;
    height: 100vh;

    .buy_sellshade {
        position: absolute;
     width: 100%;
     height: 100vh;
        right: 20px;
        top: 58px;
        left: 91px;
        bottom: 58px;
    }
    .only_shadow {
        position: absolute;
        background-color: #eab721;
        filter: blur(252px);
        right: 0px;
        bottom: 0px;
        width: 240px;
        height: 240px;
    }
    .set-internal-img{
        position: relative;
        .swap_img{
            position: absolute !important;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%);
        }
    }
    .main_headouter_div {
        border-radius: 15px;
        border: 1.5px solid rgba(255, 255, 255, 0.05);
        background: rgba(17, 19, 22, 0.25);
        backdrop-filter: blur(5px);
        padding: 20px;
    
   
        h2 {
            color: #fff;
            text-align: center;
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 800;
            line-height: 30px;
            margin-bottom: 15px;
        }
        .inner_data {
            border-radius: 10px;
            border: 1px solid rgba(255, 255, 255, 0.05);
            background: rgba(255, 255, 255, 0.02);
            display: flex;
            padding: 20px;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            .usdt {
                display: flex;
                gap: 10px;
                border-radius: 40px;
                border: 1px solid rgba(255, 255, 255, 0.03);
                background: rgba(255, 255, 255, 0.03);
                display: flex;
                padding: 6px 10px 6px 6px;

                align-items: center;
                gap: 10px;
                p.innerpara {
                    color: #fff;
                    font-feature-settings:
                        "clig" off,
                        "liga" off;
                    /* font-family: Montserrat; */
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 100%;
                    letter-spacing: 0.1px;
                }
            }
            .usdtss {
                text-align: end;
                p.balancepara {
                    color: #717171;

                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    span {
                        color: #28e664;

                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                    }
                }
                h4.figur {
                    color: var(--light-text, #3e3e3e);
                    font-family: Montserrat;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    margin-top: 5px;
                }
            }
        }

        .last_paraouter_div {
            margin: 25px 0px;
            .last_para {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                align-self: stretch;
                padding-bottom: 10px;
                p.cost {
                    color: var(--light-text, #3e3e3e);
                    text-align: center;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
                h6.dolos {
                    color: #fff;
                    text-align: right;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
    }
}
.login {
    border-radius: 8px;
    background: var(
        --primary-gradient,
        linear-gradient(90deg, #f9c924 0%, #fff98c 26.52%, #e4af18 73.21%, #ffd440 100%)
    );
    display: flex;
    padding: 15px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    color: #000;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    width: 100%;
    border: 1px solid transparent;
}
.buy-sellmodal {
    div#exampleModal {
        background: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(5px);
        .modal-content {
            display: flex;
            max-width: 524px;
            padding: 30px 30px 40px 30px;
            align-items: flex-start;
            gap: 10px;
            flex-shrink: 0;
            border-radius: 5px;
            border: 1px solid var(--stroke, #0c0c0c);
            background: #060606;
            box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.05);
            .modal-header {
                display: flex;
                padding: 12px 15px;
                justify-content: space-between;
                align-items: center;
                align-self: stretch;
                border-radius: 8px;
                border: 1px solid var(--stroke, #0c0c0c);
                button.btn-close {
                    padding: 0px;
                    border: 2px solid transparent;
                    display: flex;
                    text-align: center;
                    align-items: center;
                }
                h5#exampleModalLabel {
                    color: var(--white, #fff);
                    font-family: Montserrat;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 110%;
                }
            }
            .modal-body {
                padding: 0px 0px 0px 10px;
                text-align: center;
                margin-top: 30px;
                margin-bottom: -20px;
                p.modalpara {
                    color: var(--secondary-white, #fff);
                    text-align: center;
                    font-family: Montserrat;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                    margin-bottom: 40px;
                    span {
                        color: var(--primary-color, #eab721);
                        font-family: Montserrat;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%;
                    }
                }
            }
        }
    }
}


@media (max-width: 750px) {
    .buy_sell .main_headouter_div h2 {
        font-size: 18px;
    }
    .buy_sell .main_headouter_div .inner_data {
        padding: 10px;
    }

}

@media (max-width: 450px) {
 
    .buy_sell .main_headouter_div {
        padding: 10px;
    }
}
@media (max-width: 400px) {

}


