.main-footer {
    // background-image: url("../../../Assets/footer-background.png");
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 100px;
    padding-bottom: 340x;
    gap: 10px;

    background: #020202;
    margin-bottom: -45px;

    ul.navbar-nav.mr-auto {
        // margin-left: 80px;
        /* margin-right: 44px; */
        li.nav-item {
            // margin-right: 35px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 100%;
            color: #9d9d9d;
            transition: 0.7s;
            &:hover {
                color: #ff0083;
            }
        }
    }
    .dehvdgh {
        padding-bottom: 54px;
    }

    .main-upper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    h4 {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .inner-footer-end {
        padding-top: 40px;
        padding-bottom: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p.right-paras {
            color: var(--light-text, #3E3E3E);
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    .mt5 {
        margin-top: 50px;
    }

    .w28 {
        width: 28px;
    }

    li {
        // margin-top: 10px;
        // margin-bottom: 10px;
    }

    .inner-icon {
    }

    .parent {
        display: flex;
        justify-content: start;
        align-items: center;
        .logo-img {
            a {
                margin-right: 76px;
            }
        }
        .text-footer {
            a {
                margin-bottom: 0px;
                padding-bottom: 0px;
                margin-right: 40px !important;
                font-family: "Gmedium";
                font-style: normal;
                // font-weight: 500;
                font-size: 16px;
                text-decoration: none !important;
                line-height: 110%;
                color: #9d9d9d;
                font-size: 16px;
                &:hover {
                    color: black;
                    border-bottom: 2px solid #ff0083;
                }
            }
        }
        .padd {
            padding: 0 !important;
        }
    }

    .button-footer {
        float: right;
        display: block;
    }
    .rights {
        padding-top: 16px;
        padding-bottom: 26px;
        // background: #ffffff;
    }
    .last-footertop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 40px;
        p.fotr-para {
            color: var(--light-text, #3E3E3E);
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            padding-top: 25px;
            // width: 40%;
            width: 100%;
        }
        ul.navbarnavmr-auto {
            display: flex;
            // gap: 35px;
            li.nav-item {
                color: #fff;
                font-family: Ubuntu;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}
.inner-rightsscsd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    .sdgvsgdv {
        color: #fff;
        font-family: Ubuntu;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        // margin-left: 10px;
        span {
        }
    }
    .dvsgd {
        font-style: normal;
        // font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: #aeaeae;
    }
}
.navbarnavmr-auto.dvsgd {
    display: flex;

    gap: 15px !important;
}

@media (max-width: 768px) {
    .main-footer .main-upper {
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
    }
    .main-upper {
        .right {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
    .main-footer .parent {
        flex-direction: column;
    }
    .main-footer .parent .logo-img a {
        margin-right: 0px;
    }
    .main-footer .shdbshd {
        margin-left: 0px;
    }
    .main-footer .parent .logo-img {
        margin-bottom: 22px;
    }
    .text-footer {
        margin-bottom: 22px;
    }
    .main-footer .parent .text-footer a {
        margin-right: 14px !important;
        margin-left: 14px;
    }
    .main-footer {
        // margin-top: 50px;
    }
}
@media (max-width: 768px) {
    .main-footer .last-footertop ul.navbarnavmr-auto {
        display: flex;
        text-align: center;
        justify-content: center;
        margin-top: 15px;
    }
    .main-footer .last-footertop p.fotr-para {
        width: 100%;
    }
    .main-footer .last-footertop {
        display: grid;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .inner-rightsscsd {
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
    }
    .inner-rightsscsd .sdgvsgdv {
        margin-bottom: 10px;
    }
}

.footerdns {
    text-transform: uppercase !important;
    cursor: pointer;
    font-weight: 500;
}
@media (max-width: 991px) {
    .main-footer {
        // background: #ffffff !important;
        // margin-top: unset !important;
    }
    .main-footer .navbar-brand {
        display: block;
        width: 100%;
        text-align: center;
    }
    .main-footer .navbar-nav {
        display: block;
        width: 100%;
        margin: 20px auto !important;
        text-align: center;
    }
    .main-footer .navbar {
        justify-content: center;
    }
    .main-footer ul.navbar-nav.mr-auto li.nav-item {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .main-footer .last-footertop p.fotr-para {
        width: 100%;
    }
    .main-footer {
        text-align: center;
    }
    .main-footer .inner-footer-end {
        flex-direction: column;
        gap: 10px;
    }
}

@media (max-width: 430px) {
    .main-footer .navbar-brand {
        display: block;
        width: 100%;
    }
    .main-footer .navbar-nav {
        display: block;
        width: 100%;
        margin: 20px auto !important;
    }
    .main-footer .navbar {
        justify-content: center;
    }
}
