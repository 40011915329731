.main-propertyn {
    padding-top: 70px;
    padding-bottom: 70px;

    .main_head {
        h2 {
            color: #fff;
            font-size: 45px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            // margin-bottom: 60px;
        }
    }

    .maincard {
        border-radius: 10px;
        border: 2px solid rgba(255, 255, 255, 0.04);
        background: #020202;
        width: 100%;

        .mainimage {
            width: 100%;
            height: 265px;
            object-fit: cover;
            object-position: center;

            .houseimg {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 10px 10px 0px 0px;
            }
        }

        .cardheading {
            padding: 15px;

            .innertext {
                h3 {
                    color: #fff;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-transform: capitalize;
                    margin-bottom: 15px;
                }
            }

            .brdr {
                border: 1px solid #0f0f0f;
                width: 100%;
                margin: 15px 0px;
            }

            .type {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .left {
                    h4 {
                        color: #8d8d8c;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                        margin-bottom: 15px;
                    }
                }

                .right {
                    button {
                        border-radius: 70px;
                        background: rgba(234, 183, 33, 0.19);
                        width: 100%;
                        padding: 4px 10px;
                        border: none;
                        margin-bottom: 15px;
                    }

                    p {
                        color: #8d8d8c;

                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;

                        span {
                            background: var(
                                --primary-gradient,
                                linear-gradient(90deg, #f9c924 0%, #fff98c 26.52%, #e4af18 73.21%, #ffd440 100%)
                            );
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }
    }
}

section.main_investors {
    .invester_outerdiv {
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.04);
        background: #020202;
        display: flex;
        padding: 20px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        margin-top: 60px;
        p.innerparas {
            color: #8d8d8c;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
        }
        .left_firstimg {
            // width: 100%;
            // min-width: 100%;
            // max-width: 404px;
            border-radius: 15px;

            img.sliderimggs {
                /* width: 404px; */
                /* height: 375px; */
                border-radius: 15px;
                // width: 100%;
                /* max-width: 404px; */
                // min-width: 404px;
            }
        }
        .left_firstimgss {
            // margin-left: -49px;
            // width: 133px;
            // height: 111.604px;
            // flex-shrink: 0;
            img.sliderimggs {
                border-radius: 15px;
                // width: 133px;
                // height: 111.604px;
            }
        }
    }
    .main_leftheadingdiv {
        h1.Propertiesfsthndg {
            color: #fff;
            font-family: Montserrat;
            font-size: 26px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            margin-bottom: 15px;
        }
        p.inneerrs_pars {
            color: #ffff;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%;
            text-transform: capitalize;
        }
        .text_flexdiv {
            display: flex;

            align-items: center;
            gap: 17px;

            justify-content: space-between;
            align-items: center;
            margin-top: 25px;
            margin-bottom: 25px;
            padding-top: 17px;
            padding-bottom: 20px;
            border-top: 1px solid #3e3e3e;
            border-bottom: 1px solid #3e3e3e;
        }
    }
    .investorsbtns {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        grid-gap: 10px;
        gap: 20px;
        button.investbtnn {
            padding: 15px 30px;
            border-radius: 5px;

            background: var(
                --primary-gradient,
                linear-gradient(90deg, #f9c924 0%, #fff98c 26.52%, #e4af18 73.21%, #ffd440 100%)
            );
            color: #000;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            border: 1px solid transparent;
        }
        button.viewdetails {
            border-radius: 5px;
            background: #020202;
            box-shadow: 0px 0px 14px 0px rgba(255, 212, 64, 0.2) inset;
            padding: 15px 38px;
            color: #fff;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            border: 1px solid #f9c924;
            background-clip: padding-box;
        }
    }
    .wrapper-img{
        img{
            border-radius: 10px;
        }
    }
}
.margin_top_butm {
    margin-top: 20px;
    margin-bottom: 20px;
}
.outer_imgdivmain {
    display: flex;
    gap: 15px;
    // justify-content: center;
    align-items: center;
}

.invest_modalbody.modal-body {
    text-align: center;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 40px;
    /* align-self: stretch; */
    .inermodaldiv {
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.05);
        background: rgba(255, 255, 255, 0.02);
        display: flex;
        padding: 15px 20px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        margin-top: 40px;
    }
    h6.Iccnvestment {
        color: #fff;
        font-feature-settings:
            "clig" off,
            "liga" off;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 180%;
        letter-spacing: 0.1px;
    }
    .lastinneerr {
        p.balan {
            color: #717171;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            span {
                color: #28e664;
                font-family: Montserrat;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
            }
        }
        h5{
            color: #fff;
            font-family: Montserrat;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            margin-top: 5px;
        }
    }
}
.parentinputstt {
    margin-bottom: 50px;
    text-align: center;
}
.digitbtnss {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
}
p.surree {
    color: var(--secondary-white, #fff);
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}
.digitbtnss {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    button.canccell {
        border-radius: 5px;
        background: rgba(234, 235, 240, 0.04);
        padding: 20px 30px;
        width: 100%;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        border: 1px solid #fff98c;
        border-radius: 5px;
        background: #060606;
        box-shadow: 0px 0px 14px 0px rgba(255, 212, 64, 0.2) inset;
    }
    button.loginsssaa {
        border-radius: 8px;
        background: var(
            --primary-gradient,
            linear-gradient(90deg, #f9c924 0%, #fff98c 26.52%, #e4af18 73.21%, #ffd440 100%)
        );

        color: #000;

        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        width: 100%;
        border: 1px solid #f9c924;
        padding: 17px 30px;
        width: 100%;
    }
}
@media (max-width: 1199px) {
    .outer_imgdivmain {
        display: flex;
        // grid-gap: 55px;
        // gap: 55px;
        align-items: center;
        justify-content: space-evenly;
    }

    .main_leftheadingdiv {
        margin-top: 35px;
    }
}
@media (max-width: 800px) {
    .left_firstimgss {
        margin-left: unset;
    }
    .main-propertyn {
        // margin-top: 280px;
        /* margin-bottom: 0px; */
        margin-top: 50px;
    }
    .main_leftheadingdiv {
        margin-top: 35px;
    }
    section.main_investors .investorsbtns {
        justify-content: space-around;
    }
    section.main_investors .investorsbtns button.investbtnn {
        width: 100%;
    }
    section.main_investors .investorsbtns button.viewdetails {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .onlymobilerespos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 96%;
        gap: 10px;
    }
    .outer_imgdivmain {
        display: grid;
    }
    .main-propertyn .main_head h2 {
        font-size: 25px !important;
    }
    section.main_investors .main_leftheadingdiv h1.Propertiesfsthndg {
        font-size: 25px !important;
    }
    section.main_investors .invester_outerdiv{
        padding: 20px 0;
    }
    .padd-sm{
        padding: 0 !important;
    }
    .ordersm-2{
        order: 2;
        margin-top: 60px;
    }
    .ordersm-1{
        order: 1;
    }
    section.main_investors .invester_outerdiv{
        margin-top: 0;
    }
    .main_leftheadingdiv{
        margin-top: 0;
    }
}
