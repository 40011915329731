.buytoken {
    padding: 70px 0px;
    .buytokenimage {
        // max-width: 435.404px;
        width: 100%;
        // height: 428.676px;
        // object-fit: cover;
        // object-position: center;
        text-align: center;
        .innerbuy {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .main-head {
        h2 {
            color: #fff;
            font-size: 45px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            margin-bottom: 25px;
            max-width: 600px;
            width: 100%;
        }
        p {
            color: #ffff;
         
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%; /* 25.6px */
            text-transform: capitalize;
            margin-bottom: 20px;
            max-width: 600px;
            width: 100%;
        }
    }
    .buybutton {
        margin-top: 20px;
        .login {
            padding: 15px 50px;
            color: #fff;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            position: relative;
            border: 1px solid transparent;
            background: #000;
            background-clip: padding-box;
            width: auto;
            border-radius: 5px;

            &::after {
                content: "";
                position: absolute;
                top: -1px;
                bottom: -1px;
                left: -1px;
                right: -1px;
                background: linear-gradient(90deg, #f9c924 0%, #fff98c 26.52%, #e4af18 73.21%, #ffd440 100%);
                z-index: -1;
                border-radius: 5px;
            }
        }
    }
}

@media (max-width: 1199px) {
    .buytoken .buybutton {
        margin-bottom: 35px;
    }
    .buytoken .buybutton .login {
        width: unset;
    }
    .column-reverse {
        flex-direction: column-reverse;
    }
}

@media (max-width: 600px) {
    .buytoken .main-head h2 {
        max-width: 390px;
        width: 100%;
        font-size: 30px;
    }
    .buytoken .main-head p {
        max-width: 390px;
        width: 100%;
    }
    .buytoken .buytokenimage .innerbuy {
        object-fit: unset;
    }
    .buytoken{
        padding-top: 0;
        padding-bottom: 70px;
    }
}
