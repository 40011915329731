.error-message{
    color: red !important;
}

.main-login {
    // background: url(../../src/Assests/bg-logo.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // min-height: 100vh;
    padding-bottom: 50px;

    .maincard {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 133px;
        margin: 0px auto;
        max-width: 720px;
        width: 100%;

        .logincard {
            padding: 35px 50px;
            border-radius: 20px;
            width: 100%;
            max-width: 542px;
            border-radius: 20px;
            // background: var(--login-bg);
            box-shadow: 0px 3px 50px 0px rgba(0, 0, 0, 0.04);

            .innerlogo {
                text-align: center;
            }

            .cardtext {
                h6 {
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 110%;

                    color: #fff;

                    text-align: center;
                    margin-top: 50px;
                    margin-bottom: 15px;
                    text-transform: uppercase;
                }

                p {
                    color: var(--light-text, #3e3e3e);
                    text-align: center;

                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 130%;
                    /* 18.2px */

                    margin-bottom: 40px;
                }

                input {
                    border-radius: 5px;

                    border: 1.5px solid #eab721;
                    background: #020202;
                    padding: 18px 20px;
                    width: 100%;
                    max-width: 442px;
                    margin-bottom: 5px;
                    // color: var(--cardtext);
                    padding-right: 51px;
                    color: #d4d4d4 !important;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 130%;

                    &::placeholder {
                        color: #ffff !important;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 130%;
                    }
                }

                .error-margin {
                    margin-bottom: 12px;
                }

                .parent {
                    position: relative;

                    .eye-btn {
                        cursor: pointer;
                    }

                    img {
                        position: absolute;
                        top: 20px;
                        right: 20px;
                    }
                }
            }

            .chec {
                .material-checkbox {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 400;
                }

                .material-checkbox input[type="checkbox"] {
                    position: absolute;
                    opacity: 0;
                    width: 0;
                    height: 0;
                }

                .checkmark {
                    position: relative;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    margin-right: 12px;
                    border: 2px solid #eab721;
                    border-radius: 5px;
                    transition: all 0.3s;
                }

                .material-checkbox input[type="checkbox"]:checked~.checkmark {
                    background-color: #eab721;
                    border-color: #eab721;
                }

                .material-checkbox input[type="checkbox"]:checked~.checkmark:after {
                    content: "";
                    position: absolute;
                    top: 2px;
                    left: 6px;
                    width: 4px;
                    height: 10px;
                    border: 2px solid white;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }

                .material-checkbox input[type="checkbox"]:focus~.checkmark {
                    box-shadow: 0 0 0 2px #eab721;
                }

                .material-checkbox:hover input[type="checkbox"]~.checkmark {
                    border-color: #eab721;
                }

                .material-checkbox input[type="checkbox"]:disabled~.checkmark {
                    opacity: 0.5;
                    cursor: not-allowed;
                }

                .material-checkbox input[type="checkbox"]:disabled~.checkmark:hover {
                    border-color: #eab721;
                }
            }

            .parentinputs {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 19px;
                margin-top: 10px;

                .code {
                    border-radius: 8px;

                    border: 1px solid var(--primary-color, #0c0c0c);

                    background: #020202;
                    padding: 20px 11px 19px 11px;
                    // width: 57.83px;
                    // height: 76px;
                    width: 100%;
                    color: #000000;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: var(--primary-color, #eab721);
                    font-size: 34px;
                    font-weight: 700;
                    line-height: 110%;
                    text-transform: uppercase;
                    text-align: center;

                    &:focus {
                        //   border: 1px solid var(--inputone-border);
                        border: 1px solid var(--primary-color, #eab721);
                    }

                    &::placeholder {
                        color: var(--primary-color, #eab721);
                        text-align: center;
                        font-size: 34px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 110%;
                        text-transform: uppercase;
                    }
                }
            }

            .endbtn {
                button {
                    border-radius: 5px;
                    background: var(--primary-gradient,
                            linear-gradient(90deg, #f9c924 0%, #fff98c 26.52%, #e4af18 73.21%, #ffd440 100%));
                    padding: 18px 45px;
                    width: 100%;
                    font-size: 16px;
                    color: #000;

                    font-weight: 600;
                    line-height: 100%;
                    /* 16px */

                    border: none;
                    margin-top: 40px;
                }
            }

            .endbtnsus {
                button.cussloginbtns {
                    border-radius: 5px;
                    background: var(--primary-gradient, linear-gradient(90deg, #f9c924 0%, #fff98c 26.52%, #e4af18 73.21%, #ffd440 100%));
                    padding: 18px 45px;
                    width: 100%;
                    font-size: 16px;
                    color: #000;
                    font-weight: 600;
                    line-height: 100%;
                    border: none;


                }
            }

            .digitbtns {
                button {
                    border-radius: 5px;
                    background: rgba(234, 235, 240, 0.04);
                    padding: 18px 45px;
                    width: 100%;
                    color: #fff;
                    font-size: 16px;

                    border: none;
                    margin-top: 50px;
                }
            }
        }
    }

    .last-text {
        margin-top: 41px;
        text-align: center;

        p.creat-actot {
            color: #fff;
            /* font-family: Montserrat; */
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            span {
                a {
                    color: var(--primary-color, #eab721);
                    font-family: Montserrat;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-left: 5px;
                }
            }
        }
    }

    ul.passwordmust {
        margin-top: 40px;
        margin-bottom: 40px;

        .paswodheadng {
            color: #151515 !important;
            font-size: 14px !important;
            font-weight: 600 !important;
            margin: unset !important;
            padding-bottom: 6px !important;
            text-align: start !important;
        }

        li.text-wrng {
            color: #a3a3a3 !important;

            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-decoration: dotted #eab721;
            padding-bottom: 5px;
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }

    .backtopbtn {
        position: relative;
        top: 95px;
        left: 60px;

        button.back-btn {
            border-radius: 130px;
            border: 1px solid rgba(234, 183, 33, 0.10);
            background: rgba(234, 183, 33, 0.05);
            display: inline-flex;
            padding: 10px 15px;
            justify-content: center;
            align-items: center;
            gap: 6px;
            color: #EAB721;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            gap: 10px;
        }
    }

    .innerlogosuccess {
        text-align: center;
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

@media (max-width: 600px) {
    .main-login .maincard .logincard .cardtext h6 {
        font-size: 16px;
        margin-top: 40px;
    }

    .main-login .maincard .logincard .parentinputs .code {
        padding: 17px 7px;
    }

    .main-login .maincard .logincard .parentinputs {
        gap: 10px;
        margin-top: 0px;
    }

    .main-login .maincard .logincard .digitbtns button {
        margin-top: 40px;
    }

    .main-login .maincard .logincard {
        padding: 5px !important;
        width: 100%;
    }

    .main-login .maincard .logincard .parentinputs .code {
        width: 50px;
        height: 56px;
    }
}

@media (max-width: 390px) {
    .main-login .maincard .logincard .parentinputs .code {
        width: 100%;
        height: 56px;
    }
}

.navbar-login {
    // background: var(--login-bg) !important;
    box-shadow: 0px 3px 50px 0px rgba(0, 0, 0, 0.04) !important;
    padding: 25px;

    .sidebarheader {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        gap: 8px;

        .sidebarhead {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.02em;
            // color: var(--navbar-head-text);
        }

        .logoside {
            width: 127px;
            height: 34px;
        }
    }
}

@media (max-width: 991px) {
    .navbar-login .navbar .navbarlefticons {
        display: flex !important;
    }

    .navbar-login .navbar {
        padding: 0 !important;
    }

    .ytstcftfsctfscf {
        width: 95px;
    }
}

.twice-div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
        color: var(--primary-color, #eab721);
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
    }
}

.recover2fa {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h6 {
        //   color: var(--common-text1);
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
        max-width: 305px;
        width: 100%;
        margin: 0 auto;
    }
}