.main-invest {
    padding: 110px 0px;

    .investcard {
        border-radius: 10px;
        background: #0A0A0A;
        padding: 30px;
        width: 100%;
        margin-bottom: 30px;

        .investimages {
            // max-width: 1076px;
            width: 100%;
            height: 381px;
            object-fit: cover;
            object-position: center;

            .imginner {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .innertext {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 30px;
            margin-bottom: 25px;

            .left {
                h3 {
                    color: var(--white, #FFF);
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0.5px;
                }
            }

            .right {
                .invest {
                    border-radius: 5px;
                    background: var(--primary-gradient, linear-gradient(90deg, #F9C924 0%, #FFF98C 26.52%, #E4AF18 73.21%, #FFD440 100%));
                    padding: 13px 50px;
                    width: 100%;
                    color: #000;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                }
            }
        }
      
    
        .parent-equity {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;

            .eyuity {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 15px;

                .right {
                    h6 {
                        color: var(--white, #FFF);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 0.32px;
                    }
                }
            }
        }

        .token {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 31px;
            margin-bottom: 40px;
            margin-top: 30px;

            .left {
                h3 {
                    color: var(--white, #FFF);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0.32px;

                    span {
                        color: var(--primary-color, #EAB721);
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 0.36px;
                    }
                }
            }

            .rigt {
                h3 {
                    color: var(--white, #FFF);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0.32px;

                    span {
                        color: var(--primary-color, #EAB721);
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 0.36px;
                    }
                }
            }

        }

        .progrss {
            p {
                color: var(--white, #FFF);
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.28px;
                margin-bottom: 18px;
            }

            .progress {
                border-radius: 40px;
                background: var(--field-text, #1F1F1E);
                height: 15px;

                .progress-bar {
                    border-radius: 40px;
                    background: var(--primary-gradient, linear-gradient(90deg, #F9C924 0%, #FFF98C 26.52%, #E4AF18 73.21%, #FFD440 100%));
                }
            }
        }

        .amount {
            margin-top: 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h6 {
                color: var(--primary-color, #EAB721);
                font-size: 26px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
                /* 26px */
                text-transform: capitalize;
            }

            p {
                color: var(--light-text, #3E3E3E);
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                text-transform: capitalize;
                margin-bottom: 10px;
            }
        }
    }

    .informationcard {
        border-radius: 10px;
        background: #0A0A0A;
        padding: 30px;
        width: 100%;

        .main_heading {
            h2 {
                color: var(--white, #FFF);
                font-size: 25px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.5px;
                margin-bottom: 25px;
            }
        }

        .parent {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            .left-side {
                flex: 0 0 49%;

                .inner-text {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 16px;
                    padding-top: 25px;
                    border-bottom: 2px solid #121212;
                }
            }

            p {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                /* 16px */
            }

            h6 {
                color: var(--primary-color, #EAB721);
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
                /* 16px */
            }

            .set-custom-border-left {
                border-right: 1px solid #1E1E1E;
                padding-right: 40px;
            }
        }
    }
}

@media(max-width:600px) {
    .main-invest .investcard .innertext .right .invest {
        display: none;
    }

    .main-invest .investcard .innertext .left h3 {
        font-size: 18px;
    }

    .main-invest .investcard .parent-equity .eyuity .right h6 {
        font-size: 10px;
    }

    .dotimg {
        display: none;
    }

    .main-invest .investcard .token {
        flex-direction: column;
    }

    .main-invest .investcard .token .left h3 {
        font-size: 16px;
    }

    .main-invest .investcard .token .left h3 span {
        font-size: 16px;
    }

    .main-invest .investcard .token {
        align-items: flex-start;
    }

    .main-invest .investcard .amount p {
        font-size: 14px;
    }

    .main-invest .investcard .amount h6 {
        font-size: 18px;
    }

    .main-invest .investcard .endbtnss {
        display: block !important;

    }

        .main-invest .investcard .endbtnss .invest{
           border-radius: 5px;
           background: var(--primary-gradient, linear-gradient(90deg, #F9C924 0%, #FFF98C 26.52%, #E4AF18 73.21%, #FFD440 100%));
           padding: 13px 50px;
           width: 100%;
           color: #000;
           text-align: center;
           font-size: 16px;
           font-style: normal;
           font-weight: 600;
           line-height: 24px;
           margin-top: 25px;
           border: none;
        }
        .main-invest .informationcard .parent .left-side{
            flex: 0 0 100% !important;
        }  
        .main-invest .informationcard .parent .set-custom-border-left{
            border-right: unset !important;
            padding: 0px;
        }
        .main-invest .informationcard .parent p{
            font-size: 14px;
        }
        .main-invest .informationcard .parent h6{
            font-size: 14px;
            white-space: nowrap;
        }
        .main-invest .investcard{
            padding: 30px 20px;
        }
        .main-invest .informationcard{
            padding: 30px 20px;
        }
}