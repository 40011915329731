.main-banner {
    background: url(../../../../src/Assests/bannerimg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // min-height: 100vh;
    // height: 665px;
    padding-top: 104px;

    .mainheading {
        margin-top: 90px;

        h2 {
            color: #fff;
            font-size: 55px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            max-width: 600px;
            width: 100%;
            margin-bottom: 30px;

            span {
                background: var(--primary-gradient,
                        linear-gradient(90deg, #f9c924 0%, #fff98c 26.52%, #e4af18 73.21%, #ffd440 100%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-family: Montserrat;
                font-size: 55px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: capitalize;
            }
        }

        p {
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%;
            /* 25.6px */
            text-transform: capitalize;
            width: 100%;
            max-width: 600px;
            margin-bottom: 40px;
        }
    }

    .mainbtn {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        .login {
            padding: 13px 38px;
            color: #fff;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            width: 100%;
            position: relative;
            border: 1px solid transparent;
            background: #000;
            background-clip: padding-box;
            border-radius: 5px;

            &::after {
                content: "";
                position: absolute;
                top: -1px;
                bottom: -1px;
                left: -1px;
                right: -1px;
                background: linear-gradient(90deg, #f9c924 0%, #fff98c 26.52%, #e4af18 73.21%, #ffd440 100%);
                z-index: -1;
                border-radius: 5px;
            }
        }


    }

    .bannerimage {
        max-width: 518.659px;
        width: 100%;
        height: 372.369;
        object-fit: cover;
        object-position: center;
        margin-top: 90px;
        margin-left: 50px;

        .innerimage {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
        }
    }
}

.app {
    border-radius: 5px;
    background: var(--primary-gradient,
            linear-gradient(90deg, #f9c924 0%, #fff98c 26.52%, #e4af18 73.21%, #ffd440 100%));
    padding: 15px 30px;
    width: 100%;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.mainbtn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    .login {
        padding: 13px 38px;
        color: #fff;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;

        width: 50%;
        position: relative;
        border: 1px solid transparent;
        background: #000;
        background-clip: padding-box;

        border-radius: 5px;

        &::after {
            content: "";
            position: absolute;
            top: -1px;
            bottom: -1px;
            left: -1px;
            right: -1px;
            background: linear-gradient(90deg, #f9c924 0%, #fff98c 26.52%, #e4af18 73.21%, #ffd440 100%);
            z-index: -1;
            border-radius: 5px;
        }
    }

    .app {
        border-radius: 5px;
        background: var(--primary-gradient,
                linear-gradient(90deg, #f9c924 0%, #fff98c 26.52%, #e4af18 73.21%, #ffd440 100%));
        padding: 15px 30px;
        width: 100%;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }
}


// Comming soon div 


section.main-Commingsoon {
    margin: 0px auto;
    margin-top: 150px;
    text-align: center;

    .cardtext {
        margin-top: 100px;
        text-align: center;

        p.sonnpara {
            color: #D0D0D0;
            text-align: center;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%;
            text-transform: capitalize;
            /* width: 80%; */
            padding: 50px 250px 75px 250px;
        }


    }

    button.comminghsoonbackbtn {
        border-radius: 130px;
        border: 1px solid rgba(234, 183, 33, 0.10);
        background: rgba(234, 183, 33, 0.05);
        display: inline-flex;
        padding: 10px 15px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        color: #EAB721;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 107px;
        width: 50%;

        &:hover {
            background: var(--primary-gradient,
                    linear-gradient(90deg, #f9c924 0%, #fff98c 26.52%, #e4af18 73.21%, #ffd440 100%));
            color: #000;
        }
    }
}

@media (max-width: 600px) {
    .main-banner .mainheading h2 span {
        font-size: 30px;
    }

    .main-banner .mainheading h2 {
        font-size: 30px;
    }

    .main-banner .bannerimage {
        margin-left: 0px;
    }
}