.main-navbar {
  // background: rgba(19, 21, 34, 0.70);
  // backdrop-filter: blur(12px);
  // padding: 10px 80px;
  padding: 15px 15px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  border-bottom: 1px solid rgba(255, 255, 255, 0.03);
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(3px);

  .navbar-nav {
    position: static;
    z-index: 999;
    margin-left: 15px;

    .nav-item {
      .nav-link {
        color: #c8ccd1;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: -0.015px;
        padding: 0;
        margin-right: 17px;

        &:hover {
          color: #E1A800;
        }

        &.active {
          color: #E1A800 !important;
          font-weight: 600 !important;
        }
      }
    }
  }

  .right-side {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    .dropbtn {
      display: flex;
      align-items: center;

      .login1 {
        padding: 11px 30px;
        margin-right: 10px;

        img {
          margin-right: 10px;
        }
      }
    }

    .login {
      padding: 13px 38px;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;

      position: relative;
      border: 1px solid transparent;
      background: #000;
      background-clip: padding-box;

      border-radius: 5px;

      &::after {
        content: "";
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
        background: linear-gradient(90deg, #f9c924 0%, #fff98c 26.52%, #e4af18 73.21%, #ffd440 100%);
        z-index: -1;
        border-radius: 5px;
      }

      // &:hover{
      //   border-radius: 5px;
      //   background: #050504;
      //   box-shadow: 0px 0px 14px 0px rgba(255, 212, 64, 0.20) inset;

      // }
    }

    .dropbtn {
      .dropdown {
        .dropdown-toggle::after {
          display: none !important;
        }

        .dropdown-toggle {
          border-radius: 5px;
          border: 1px solid var(--primary-color, #eab721);
          background: #050504;
          box-shadow: 0px 0px 14px 0px rgba(255, 212, 64, 0.2) inset;
          padding: 5px 10px 5px 5px;
          min-width: 150px;
          width: 100%;

          .toggleinner {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            .mainimagesss {
              width: 33px;
              height: 33px;
              object-fit: cover;
              object-position: center;

              .inneravatar {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }

            p {
              color: #fff;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        .dropdown-menu {
          border-radius: 5px;
          border: 1px solid var(--primary-color, #eab721);
          background: #060505;
          padding: 15px;
          width: 100%;
        }

        .dropdown-item {
          display: flex;
          align-items: center;
          gap: 10px;
          justify-content: flex-start;
          border-bottom: 1px solid #0c0c0c;
          padding: 15px 0px;

          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;

          &:hover {
            background: transparent;
          }
        }
      }
    }
  }
}

.launch-btn {
  border-radius: 48px;
  background: #3bdaea;
  border: none;
  padding: 8px 28px;
  color: var(--black, #131522);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.015px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
  .main-navbar .navbar-nav {
    position: static;
    transform: unset;
  }
}

@media (max-width: 600px) {
  .main-navbar {
    padding: 12px 15px;
  }

  .main-navbar .navbar-nav li a {
    justify-content: center;
    margin-bottom: 15px;
  }

  .navbar-toggler:focus {
    box-shadow: none;
    outline: none;
  }

  .main-navbar .right-side {
    flex-direction: column;
  }

  .twice-btn {
    display: flex;
    align-items: center;
  }

  .userprofile-icon-mobile {
    display: block !important;
  }
}

.offcanvas {
  background: #070707;

  .accordion-item {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }

  .accordion-button {
    padding: 20px 0;
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    border-radius: 0 !important;

    &::after {
      // background: url("../../../Assests/arrow-acc.svg") !important;
      background-size: contain !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      width: 12px;
      height: 12px;
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  .accordion-body {
    a {
      display: block;
      color: #cbcbcb;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 10px 0;
    }
  }

  .offcanvas-header .btn-close {
    // background: url("../../../Assests/close-circle.svg") !important;
    background-size: contain !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    box-shadow: none;
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none;
  }

  .offcanvas-header {
    padding-top: 25px;
  }
}

.navbtns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;

  .buttonendnav {
    border-radius: 5px;
    border: 1px solid #ffc107;
    background: #050504;
    box-shadow: 0px 0px 14px 0px rgba(255, 212, 64, 0.2) inset;
    padding: 12px;
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .main-navbar {
    padding: 15px;
  }
}

@media (max-width: 800px) {
  .navbar-collapse {
    background: #000;
    padding: 40px;
    border-radius: 15px;
  }
}

@media (max-width: 600px) {
  .main-navbar .navbar-nav .nav-item .nav-link {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .navbtns {
    // width: 100%;
    margin-bottom: 20px;
  }

  .right-side {
    text-align: center;
  }
}

// for password purpose screen scss here.........................

.wrapper-pass {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forpassword {
  max-width: 505px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  border-radius: 15px;
  background: #101010;
  box-shadow: 0px 3px 50px 0px rgba(0, 0, 0, 0.04);
  padding: 35px 50px;

  .option-field {
    margin-top: 40px;
    width: 100%;

    h6 {
      margin-bottom: 40px;

      color: #828282;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
    }

    input {
      padding: 18px;
      width: 100%;
      margin-bottom: 5px;

      border-radius: 5px;
      border: 1px solid var(--dark-stroke, #1c1c1c);
      background: var(--dark-button, #111);
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;

      &::placeholder {
        color: rgba(69, 69, 69, 0.8);
      }
    }
  }

  span {
    font-style: normal;
    font-family: "Hmedium";
    font-size: 14px;
    line-height: 130%;
    color: #d83f08;
  }

  button {
    border-radius: 50px;
    background: linear-gradient(225deg, #fd00ff 0%, #3e1adb 100%), #fff;
    border: none;
    padding: 15px;
    width: 100%;
    margin-top: 20px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.parent-for {
  position: relative;

  .mail-icon {
    position: absolute;
    top: 18px;
    left: 20px;
  }
}

.forpassword .option-field input {
  padding-left: 50px;
}

.pass-icon {
  position: absolute;
  right: 20px;
  top: 18px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .wrapper-pass {
    margin: 0 15px;
  }

  .forpassword {
    padding: 25px 20px;
  }
  .navbtns{
    flex-direction: column;
    width: 100%;
    a{
      width: 100%;
      button{
        width: 100%;
      }
    }
  }
  .main-banner .mainheading{
    margin-top: 50px !important;
  }
  .main-navbar .right-side .dropbtn{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  .main-navbar .right-side .dropbtn .login1{
    margin: 0 auto;
    width: 100%;
  }
  .main-navbar .right-side .dropbtn .dropdown .dropdown-menu{
    width: 100%;
  }
}

.wrapper-pass {
  position: relative;

  .gradient-left-top {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .gradient-right-bottom {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
}

.ciyvyydvcy{
  transform: rotate(180deg);
}

.isscrolled{
    background: #000;
} 

