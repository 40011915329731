.resetpassword {
    padding-top: 70px;

    .backbutton {
        a {
            border-radius: 130px;
            border: 1px solid rgba(234, 183, 33, 0.10);
            background: rgba(234, 183, 33, 0.05);
            padding: 10px 15px;
            width: 100%;
            color: #EAB721;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            align-items: center;
            gap: 10px;
            max-width: 89px;
        }
    }

    .resetpassword {
        display: flex;
        align-items: center;
        justify-content: center;

        .maincard {
            border-radius: 10px;
            background: #020202;
            box-shadow: 0px 3px 50px 0px rgba(0, 0, 0, 0.04);
            padding: 50px;
            width: 100%;

            .resethead {
                h2 {
                    color: var(--white, #FFF);
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 110%;
                    text-transform: uppercase;
                    margin-bottom: 15px;
                    text-align: center;
                }

                p {
                    color: var(--light-text, #3E3E3E);
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 130%;
                    width: 405px;
                    margin: 0 auto;
                    margin-bottom: 40px;
                }
            }

            .maininput {
                position: relative;
                margin-bottom: 20px;

                .iferrorshow {
                    border-radius: 5px;
                    border: 1.5px solid #FE0000;
                    background: #020202;
                    padding: 17px 20px;
                    width: 100%;
                    color: #FFF;
                }

                .red {
                    color: #FE0000;
                    margin-top: 10px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                input {
                    border-radius: 5px;
                    border: 1.5px solid var(--stroke, #0C0C0C);
                    background: #020202;
                    padding: 17px 20px;
                    width: 100%;
                    color: #FFF;

                    &::placeholder {
                        color: var(--field-text, #1F1F1E);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 130%;
                    }
                }

                .eye {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
            }

            .passwordmust {
                h4 {
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin-bottom: 6px;
                }

                .reds {
                    list-style-type: disc;
                    list-style-position: inside;

                    ::marker {
                        color: #FE0000;
                        width: 6px;
                        height: 6px;
                    }
                }
                .green {
                    list-style-type: disc;
                    list-style-position: inside;

                    ::marker {
                        color: #3EC74C;
                        width: 6px;
                        height: 6px;
                    }
                }
                ul {
                    list-style-type: disc;
                    list-style-position: inside;

                    ::marker {
                        color: #EAB721;
                        width: 6px;
                        height: 6px;
                    }

                    li {
                        color: #A3A3A3;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin-bottom: 5px;
                    }
                    .greens{
                        color: #3EC74C;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin-bottom: 5px;
                    }
                    .redss{
                        color:#FE0000;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin-bottom: 5px;
                    }
                }

            }

            .maininputs {
                position: relative;
                margin-bottom: 40px;
                margin-top: 35px;


                input {
                    border-radius: 5px;
                    border: 1.5px solid var(--stroke, #0C0C0C);
                    background: #020202;
                    padding: 17px 20px;
                    width: 100%;
                    color: #FFF;

                    &::placeholder {
                        color: var(--field-text, #1F1F1E);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 130%;
                    }
                }

                .eye {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
            }

            .confirmbtn {
                button {
                    border-radius: 5px;
                    background: var(--primary-gradient, linear-gradient(90deg, #F9C924 0%, #FFF98C 26.52%, #E4AF18 73.21%, #FFD440 100%));
                    padding: 18px 45px;
                    width: 100%;
                    color: #000;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 100%;
                    border: none;
                }
            }
        }
    }
}
@media(max-width:600px){
    .resetpassword .resetpassword .maincard .resethead h2{
        font-size: 18px;
        white-space: nowrap;
    }
    .resetpassword .resetpassword .maincard .resethead p{
        font-size: 11px;
        width: unset !important;
    }
    .resetpassword .resetpassword .maincard{
        padding: 0px;
    }
}