.lastsection {
    background: url(../../Assests/lastbg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // max-width: 1220px;
    width: 100%;
    padding: 110px;
    // height: 465px;
    border-radius: 38px;

    .headingparentss {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        // padding: 70px;

        h2 {
            color: #fff;
            text-align: center;
            font-size: 50px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            text-transform: uppercase;
            margin-bottom: 30px;

            span {
                background: var(
                    --primary-gradient,
                    linear-gradient(90deg, #f9c924 0%, #fff98c 26.52%, #e4af18 73.21%, #ffd440 100%)
                );
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 50px;
                font-style: normal;
                font-weight: 700;
                line-height: 110%;
                text-transform: uppercase;
            }
        }

        p {
            color: rgba(255, 255, 255, 0.8);
            margin-bottom: 10px;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            max-width: 699px !important;
            width: 100% !important;
        }

        .logins {
            margin-top: 20px;

            .login {
                padding: 15px 50px;
                color: #fff;

                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
                position: relative;
                border: 1px solid #f9c924;

                background-clip: padding-box;
                margin-top: 50px;
                border-radius: 5px;
                background: #000;
                box-shadow: 0px 0px 14px 0px rgba(255, 212, 64, 0.2) inset;

                &::after {
                    content: "";
                    position: absolute;
                    top: -1px;
                    bottom: -1px;
                    left: -1px;
                    right: -1px;
                    background: linear-gradient(90deg, #f9c924 0%, #fff98c 26.52%, #e4af18 73.21%, #ffd440 100%);
                    z-index: -1;
                    border-radius: 5px;
                }
            }
        }
    }
}
@media (max-width: 800px) {
    .lastsection {
        padding: 25px;
    }
    .lastsection .headingparentss h2 {

        font-size: 30px;

    
    }
    .lastsection .headingparentss h2 span {
        font-size: 30px;
    }
}
@media (max-width: 600px) {
    .lastsection {
        background: url("../../Assests/last-bg-mobile.png") !important;
        border-radius: 10px;
    }
    .lastsection .headingparentss h2 {
        font-size: 30px;
    }
    .lastsection .headingparentss h2 span {
        font-size: 30px;
    }
}
