.main-Propertydetail {
    margin-top: 145px;
    // margin-bottom: 110px;
    .backtopbtns {
        margin-bottom: 100px;

        padding-left: 35px;
    }
    .inner-right {
        h6.fst-hedng {
            color: #fff;

            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding-bottom: 20px;
        }
        h4.sed-hedng {
            color: #fff;

            font-size: 41px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding-bottom: 20px;
        }
        p.fst-para {
            color: var(--light-text, #3e3e3e);

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            padding-bottom: 20px;
        }
        p.sec-para {
            color: var(--light-text, #3e3e3e);

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            padding-bottom: 20px;
        }
        .total-investment {
            display: flex;
            padding: 16px 18px;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            border-radius: 5px;
            border: 1px solid var(--stroke, #0c0c0c);
            background: #020202;
            margin-bottom: 15px;
            h5.investment {
                color: var(--light-text, #3e3e3e);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
            }
            h5.investment-amount {
                color: var(--primary-color, #eab721);
                text-align: right;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
            }
        }
    }
    .sec-summary-section {
        border: 1px solid var(--stroke, #0c0c0c);
        background: #020202;
        padding: 20px;
        align-items: flex-start;
        margin-top: 60px;
        margin-bottom: 20px;
        h4.ssummary {
            color: #fff;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            margin-bottom: 30px;
        }
        .iner-flexdiv {
            display: flex;
            padding: 9px 0px;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            h4.sumry-property {
                color: #fff;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
            }
            h4.sumry-propertydig {
                color: var(--primary-color, #eab721);
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
            }
        }
    }

    // about section

    .inner-left {
        h6.fst-hedngs {
            color: #fff;
            font-size: 45px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            padding-bottom: 25px;
        }
        p.fst-para {
            color: #8d8d8c;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%;
            text-transform: capitalize;
            padding-bottom: 25px;
        }
        p.sndd-para {
            color: #8d8d8c;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%;
            text-transform: capitalize;
        }
    }
    .about-img {
        img.img-fluid.about-img {
            width: 974px;
            height: 466px;
            border-radius: 10px;
        }
    }

    // Slider

    .slider-main-img {
        margin-bottom: 30px;

        img.img-fluid.slide-img {
            width: 100%;
            border-radius: 10px;
        }
    }
    .sider-iner-imgs {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .carousel-indicators {
        position: relative;
        /* right: 19px; */
        bottom: -500px;
        /* left: 0; */
        z-index: 2;
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin-right: 15%;
        margin-bottom: 1rem;
        margin-left: 15%;
    }

    .thumbnails {
        display: flex;
        margin: 0rem auto 0px;
        padding: 0;
        justify-content: center;
    }

    .thumbnail {
        width: 70px;
        height: 70px;
        overflow: hidden;
        list-style: none;
        margin: 0 0.2rem;
        cursor: pointer;
    }

    .thumbnail img {
        width: 100%;
        height: auto;
    }
}


@media (max-width:600px){
    .main-Propertydetail .backtopbtns{
        padding-left: 0;
        margin-bottom: 40px;
    }
}