.mainwork {
    padding-bottom: 70px;

    .mainheading {
        h2 {
            color: #FFF;
            font-size: 45px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            text-align: center;
            margin-bottom: 60px;
        }
    }

    .parentwork {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .maincard {
            border-radius: 10px;
            border: 4px solid #0C0C0C;
            padding: 35px 25px;
            width: 100%;
            background: transparent;
            min-height: 283px;

            .upperimages {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .right {
                    width: 35px;
                    height: 35px;
                    object-fit: cover;
                    object-position: center;

                    .search {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                .left {
                    max-width: 35px;
                    width: 100%;
                    height: 35px;
                    object-fit: cover;
                    object-position: center;

                    .one {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }

            .headings {
                h2 {
                    color: #FFF;
                    max-width: 217px;
                    width: 100%;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;
                    margin-bottom: 10px;
                    margin-top: 35px;
                }

                p {
                    color: #ffff;
                    max-width: 217px;
                    width: 100%;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .mainwork .parentwork {
        display: flow;
    }

    .mainwork .parentwork .maincard {

        margin-bottom: 20px;
    }
}

@media(max-width:600px) {
    .mainwork .mainheading h2 {
        font-size: 30px;
    }

    .mainwork .parentwork {
        grid-template-columns: repeat(1, 1fr);
    }
}